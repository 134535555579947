
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'element-plus/theme-chalk/display.css';

.form-title {
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 2px;
}

.form-title+.el-select,
.form-title+.el-select-v2,
.form-title+.el-date-editor.el-input {
  width: 100%;
}
